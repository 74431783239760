import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import { media, theme } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'
import { Headline, ScheduleList } from '@dfds-ui/react-components'

import { FlexCard } from '../Card'
import { AspectImage } from '../Image'

import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

const TripAtAGlance = (props) => {
  const { t } = useTranslation()
  const { daysCollection, ...rest } = props

  return (
    <FlexCard {...rest} noPadding={true}>
      <Swiper
        navigation={false}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        css={css`
          z-index: unset !important;
          width: 100vw;
          margin: 0 !important;

          ${media.greaterThan('m')`
            width: calc(100vw - 80px);
          `}

          ${media.greaterThan('l')`
            max-width: 380px;
          `}

          .swiper,
          .swiper-wrapper,
          .swiper-pagination {
            z-index: unset;
          }
          .swiper-pagination {
            position: relative;
            margin-top: 40px;
          }
          .swiper-pagination-bullet {
            width: 16px;
            height: 16px;
          }
          .swiper-pagination-bullet-active {
            background: ${theme.colors.secondary.main};
          }
        `}
      >
        {daysCollection.items.map((item, index) => {
          const scheduleItems = item.entriesCollection.items.map((entry) => {
            return {
              time: entry.time,
              text: entry.text || entry.description,
            }
          })
          return (
            <SwiperSlide key={item.sys.id}>
              <AspectImage
                aspectRatio={'2/1'}
                border={false}
                imageBehaviour={'cover'}
                {...item.image}
              />
              <Headline
                as={'h2'}
                css={css`
                  text-align: center;
                  margin-top: 16px;
                `}
              >
                {t(`TRIP-AT-A-GLANCE-DAY-${index + 1}`)}
              </Headline>
              <ScheduleList
                items={scheduleItems}
                css={css`
                  p {
                    margin: 0;
                  }
                `}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </FlexCard>
  )
}

export const TripAtAGlanceFragment = graphql`
  fragment TripAtAGlance on contentful_TripAtAGlance {
    daysCollection(limit: 4) {
      items {
        sys {
          id
        }
        image {
          ...Asset
        }
        entriesCollection(limit: 6) {
          items {
            time
            description
          }
        }
      }
    }
  }
`

export default TripAtAGlance
